import React from "react"

export const Sun = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264.524 264.525">
    <defs />
    <path
      fill="#001a33"
      d="M118.59 62.925h27.345V0c-18.45 11.97-26.447 42-27.346 62.925M118.59 264.525c18.449-11.969 26.446-42 27.345-62.925h-27.346v62.925"
    />
    <path d="M98.704 71.113l14.048-5.817-12.968-31.305c-5.901 12.554-8.627 17.597-1.08 37.122M151.772 199.23l12.967 31.306c5.904-12.555 8.626-17.599 1.08-37.124l-14.047 5.819M178.787 39.81c-13.052 4.703-18.546 6.342-27.015 25.486l14.048 5.817 12.967-31.304M85.737 224.716c13.052-4.703 18.546-6.344 27.015-25.485l-14.047-5.819-12.968 31.304M193.41 98.707l5.82 14.046 31.305-12.968c-12.556-5.902-17.6-8.625-37.124-1.078M33.99 164.741c12.554 5.903 17.6 8.627 37.122 1.08l-5.819-14.046L33.99 164.74M199.23 151.773l-5.82 14.048 31.307 12.967c-4.706-13.052-6.345-18.544-25.488-27.015M65.293 112.752l5.819-14.045-31.304-12.968c4.704 13.052 6.344 18.545 25.485 27.013M190.959 92.901l44.494-44.493c-21.508-4.584-48.397 10.996-63.83 25.157l19.336 19.336M73.565 171.623l-44.494 44.494C50.58 220.7 77.469 205.121 92.9 190.96l-19.336-19.337" />
    <path
      fill="#001a33"
      d="M201.599 145.936h62.925c-11.968-18.45-41.999-26.447-62.925-27.345v27.345M62.924 118.59H0c11.97 18.449 41.998 26.447 62.924 27.346v-27.345"
    />
    <path d="M171.623 190.96l44.494 44.493c4.584-21.508-10.997-48.397-25.16-63.83l-19.334 19.337M92.9 73.565L48.407 29.071C43.825 50.58 59.403 77.469 73.564 92.9L92.9 73.565" />
    <path d="M132.263 191.936c-32.956 0-59.674-26.717-59.674-59.673S99.307 72.59 132.263 72.59s59.672 26.716 59.672 59.672c0 32.956-26.716 59.673-59.672 59.673zm0-132.147c-40.027 0-72.475 32.448-72.475 72.474 0 40.025 32.448 72.474 72.475 72.474 40.025 0 72.473-32.449 72.473-72.474 0-40.026-32.448-72.474-72.473-72.474" />
  </svg>
)
export const Moon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 309.08 327.286">
    <defs />
    <path
      fill="#001a0c"
      d="M302.595 234.678c-38.485 34.876-97.22 42.468-144.785 12.882-50.126-31.179-70.244-95.367-46.884-149.58 22.166-51.443 74.726-78.736 126.233-72.067 4.224.547 6.163-5.143 2.393-7.123-.092-.048-.182-.096-.273-.142-81.955-42.839-183.57-9.386-223.633 74.909-36.428 76.643-7.202 169.885 66.457 212.021 81.012 46.343 183.54 15.81 226.379-66.144.048-.09.096-.181.142-.273 1.967-3.779-2.874-7.343-6.03-4.483"
    />
  </svg>
)
